import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timezone' })
export class TimezonePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value.replace("_", " ");
  }

}
