import { afterNextRender, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@environments/environment";
import { getCookieValue } from "@shared/utils/cookies";
import { BehaviorSubject, Subject } from "rxjs";
import { AppConsts } from "../AppConsts";

import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

@Injectable({
  providedIn: "root",
})
export class DataChangedBroadcastService {
  ExternalCalAccountsChanged$ = new Subject<void>();
  CalendarEventsChanged$ = new Subject<void>();
  EventTypesChanged$ = new Subject<void>();
  UserInfoChanged$ = new Subject<void>();
  NotificationsChanged$ = new Subject<void>();
  OwnerOrganizationChanged$ = new BehaviorSubject<void>(null);
  OrganizationsChanged$ = new BehaviorSubject<void>(null);
  OrganizationInvitationsChanged$ = new BehaviorSubject<void>(null);

  DataBroadcastCacheName$ = new BehaviorSubject<string>(null);

  connected$ = new BehaviorSubject(false);
  constructor(private matSnackBar: MatSnackBar) {
    afterNextRender(() => {
      this.start();
    });
  }
  connection: HubConnection;
  start() {
    if (this.connection?.state == HubConnectionState.Connected) {
      console.debug("SignalR tried to start again but is already connected");
      return;
    }

    var encryptedAuthToken = getCookieValue(
      AppConsts.authorization.encrptedAuthTokenName
    );
    console.log("Got auth token from cookie: " + encryptedAuthToken);
    if (!encryptedAuthToken) {
      console.info("No auth token, not starting SignalR");
      return;
    }

    console.info("Starting signalR...");
    this.connection = new HubConnectionBuilder()
      .withUrl(environment.remoteServiceBaseUrl + "/signalr-cacheRefresh", {
        accessTokenFactory: () => encryptedAuthToken,
      })
      .build();

    this.connection.on("refreshCache", (cacheName) => {
      // Register for incoming messages
      console.log("SignalR received message for cacherefresh: " + cacheName);
      if (cacheName == "NotificationsCacheName") {
        // this should only be used when updates were makred read
        this.NotificationsChanged$.next(null);
      } else if (cacheName == "EventTypesCache") {
        this.EventTypesChanged$.next(null);
      } else if (cacheName == "CalendarEventsCache") {
        this.CalendarEventsChanged$.next(null);
      } else if (cacheName == "UserInfo") {
        this.ExternalCalAccountsChanged$.next(null);
        this.UserInfoChanged$.next(null);
      } else if (cacheName == "OwnerOrganization") {
        this.OwnerOrganizationChanged$.next(null);
      } else if (cacheName == "Organizations") {
        this.OrganizationsChanged$.next(null);
      } else if (cacheName == "OrganizationInvitation") {
        this.OrganizationInvitationsChanged$.next(null);
      }

      this.DataBroadcastCacheName$.next(cacheName);
    });

    this.connection.start().catch((err) => {
      console.error("SignalR connection failed: " + err);
    });

    // When we get any kind of notification, display it as a popup and refresh the notificiation cache
    abp.event.on("abp.notifications.received", (userNotification) => {
      console.log("Notification update recieved");
      this.matSnackBar.open(userNotification.message);
      this.NotificationsChanged$.next(null);
    });
  }
}
