import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";

import { DatePipe, NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { TimezonePipe } from "@app/timezone.pipe";
import { CalendarEventDto } from "@shared/service-proxies/AdminEvent-client";
import { GuestCalendarServiceProxy } from "@shared/service-proxies/GuestCalendar-client";
import { Observable } from "rxjs";

@Component({
  selector: "app-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.scss"],
  imports: [NgIf, MatIcon, DatePipe, TimezonePipe],
})
export class EventDetailsComponent
  extends AppComponentBase
  implements OnChanges
{
  public calevent: Observable<CalendarEventDto>;

  @Input() id;
  meetingPassword: string;
  meetinglink: string;

  constructor(private guestCalendarService: GuestCalendarServiceProxy) {
    super();
  }

  @Input() eventName: string;
  @Input() location: string;
  @Input() start: Date;
  @Input() end: Date;
  @Input() description: string;
  @Input() duration: number;
  @Input() timeZone: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["id"] && changes["id"].currentValue) {
      this.guestCalendarService
        .getAttendanceDetails(changes["id"].currentValue)
        .subscribe((c) => {
          this.eventName = c.eventName;
          this.location = c.location;
          this.start = c.startTime;
          this.end = c.endTime;
          this.description = c.description;
          this.duration = c.duration;
          this.timeZone = c.timeZone;
          this.meetinglink = c.calendarEventZoomMeetingUrl;
          this.meetingPassword = c.calendarEventZoomMeetingPassword;
        });
    }
  }
}
