// "Staging" enabled environment

export const environment = {
  appName: "Calendr",
  i18nPrefix: "",
  name: "stage",
  test: true,
  domain: "test.calendr.it",
  appConfig: "appconfig.stage.json",
  backend: "https://api.test.calendr.it/",
  hostname: "https://test.calendr.it/",
  loginpage: "https://test.calendr.it/",
  meetinghostname: "https://meeting.test.calendr.it/",
  production: false,
  hmr: false,
  stripePublishableKey: "pk_test_bMQgEKdeX5wGafxPJPMGZ87j00ZVz6jL9T",
  firebasefunctionsurl:
    "https://us-central1-test-calendr.cloudfunctions.net/sendgridEmail/",
  remoteServiceBaseUrl: "https://api.test.calendr.it",
  appBaseUrl: "https://test.calendr.it",
  meetingAppBaseUrl: "https://meeting.test.calendr.it",
  zoomClientId: "6q3JT93nTmWn0zlSrbxOvA",
  zoomRedirectUrl: "https://api.test.calendr.it/api/TokenAuth/AddZoom",
  gtm: "GTM-WSWTBLRV",
  gameasurementid: "G-N4F7S4KRWZ",
  recaptchakey: "6LehJccaAAAAAET4Z21sfrUsjYX2OV8sevvv7mq5",
  stripeClientId: "ca_IqJZhynacFruaFrlZd4ipGmgnp74vMWr",
  prices: {
    usd: {
      year: "$8",
      month: "$10",
    },
    eur: {
      year: "€8",
      month: "€10",
    },
    gbp: {
      year: "£8",
      month: "£10",
    },
    jpy: {
      year: "¥680",
      month: "¥850",
    },
  },
};
