import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";

@Component({
    selector: "app-guest-ad",
    templateUrl: "./guest-ad.component.html",
    styleUrls: ["./guest-ad.component.scss"],
})
export class GuestAdComponent extends AppComponentBase {
  constructor(@Inject(DOCUMENT) public document: Document) {
    super();
  }
}
