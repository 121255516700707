import { Component, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from "@angular/material/dialog";

@Component({
  selector: "app-confimdialog",
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
  template: `
    @if (data.type === "confirm") { @if (data.maintext && data.subtext) {

    <h1 mat-dialog-title i18n>{{ data.subtext }}</h1>

    <mat-dialog-content>
      <p>{{ data.maintext }}</p>
    </mat-dialog-content>

    } @else if (data.maintext) {

    <h1 mat-dialog-title i18n>Are you sure?</h1>

    <mat-dialog-content>
      <p>{{ data.maintext }}</p>
    </mat-dialog-content>
    } @else {
    <h1 mat-dialog-title i18n>Are you sure?</h1>
    }

    <mat-dialog-actions>
      <button mat-button (click)="onNoClick()" i18n>Cancel</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial i18n>
        Ok
      </button>
    </mat-dialog-actions>
    } @else if (data.type === "info") {

    <mat-dialog-content>
      <p>{{ data.maintext }}</p>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial i18n>
        Ok
      </button>
    </mat-dialog-actions>
    }
  `,
})
export class MessageDialog {
  readonly dialogRef = inject(MatDialogRef<MessageDialog>);
  readonly data = inject<ConfirmDialogData>(MAT_DIALOG_DATA);

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface ConfirmDialogData {
  maintext: string;
  subtext: string;
  type: "confirm" | "info" | "error";
}
