import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable, signal } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  isHandset$ = new BehaviorSubject<boolean>(true);
  isHandset = signal(true);
  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      // lighthouse says mobile is 412px
      // this is assumed ultra wide on prerender, so always handset false
      .observe("(max-width: 800px)")
      .subscribe((result) => {
        // Do something with the result
        this.isHandset$.next(result.matches);
        this.isHandset.set(result.matches);
      });
  }
}

// want to know what size prerendering thinks the screen is
// to do this we can only show an image if at a certain size
