<div style="text-align: center">
  <!-- <img src="/assets/images/big-c.webp" height=20 alt="calendr logo"> -->
  <div i18n style="color: gray">
    Powered by
    <a
      style="color: gray"
      [href]="
        hostname +
        '?utm_source=guest-page&utm_medium=guest-page-bottom-ad&utm_campaign=sign%20up&utm_content=' +
        document.location.href
      "
      target="_blank"
    >
      <strong> Calendr </strong></a
    >
  </div>
</div>
