import { DOCUMENT } from "@angular/common";
import { ElementRef, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "@env/environment";
import { AppConsts } from "@shared/AppConsts";
import { MessageDialog } from "@shared/message-dialog.component";
import { AppSessionService } from "@shared/session/app-session.service";
import {
  AbpMultiTenancyService,
  FeatureCheckerService,
  LocalizationService,
  MessageService,
  NotifyService,
  PermissionCheckerService,
  SettingService,
} from "abp-ng2-module";
import { BehaviorSubject } from "rxjs";
import { ScreenSizeService } from "./screensize.service";
import { SessionServiceProxy } from "./service-proxies/Session-client";

export abstract class AppComponentBase {
  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: NotifyService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  public appSession: AppSessionService;
  elementRef: ElementRef;
  public snackBarService: MatSnackBar;

  meetinghostname = environment.meetinghostname;
  hostname = environment.hostname;
  apiurl = environment.backend;
  isHandset$ = new BehaviorSubject<boolean>(false);
  document: Document;
  isTest: boolean;
  isDev: boolean;
  isProduction: boolean;
  sessionService: SessionServiceProxy;
  dialog: MatDialog;
  screenSizeService: ScreenSizeService;

  constructor() {
    this.localization = inject(LocalizationService);
    this.permission = inject(PermissionCheckerService);
    this.feature = inject(FeatureCheckerService);
    this.notify = inject(NotifyService);
    this.setting = inject(SettingService);
    this.message = inject(MessageService);
    this.multiTenancy = inject(AbpMultiTenancyService);
    this.appSession = inject(AppSessionService);
    this.elementRef = inject(ElementRef);

    this.snackBarService = inject(MatSnackBar);
    this.sessionService = inject(SessionServiceProxy);
    this.dialog = inject(MatDialog);
    this.screenSizeService = inject(ScreenSizeService);

    this.document = inject(DOCUMENT);
    this.isTest = environment.name == "stage";
    this.isDev = environment.name == "";
    this.isProduction = environment.name == "production";

    // shortcut because we want to use this locally
    this.screenSizeService.isHandset$.subscribe((result) => {
      this.isHandset$.next(result);
    });
  }

  get now() {
    return Date.now();
  }

  clickCopy() {
    // rhyse asked to remove
    this.snackBarService.open($localize`Copied to clipboard!`, null, {
      duration: 20000,
    });
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(
      key,
      this.localizationSourceName
    );

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }

  log(a) {
    console.debug(a);
  }

  get fullname() {
    return this.appSession.user.name + " " + this.appSession.user.surname;
  }
  get companyname() {
    return this.appSession.user.companyName;
  }

  get profilepic() {
    return this.appSession.user.profilePic;
  }

  error(message) {
    this.snackBarService.open(message, null, {
      duration: 20000,
    });
  }

  confirm(
    firsttext: string,
    secondtext: string = "",
    callback: (r: boolean) => void
  ) {
    this.dialog
      .open(MessageDialog, {
        data: { maintext: firsttext, subtext: secondtext, type: "confirm" },
      })
      .afterClosed()
      .subscribe(callback);
  }

  info(firsttext: string, secondtext: string = "") {
    this.dialog.open(MessageDialog, {
      data: { maintext: firsttext, subtext: secondtext, type: "info" },
    });
  }
}
