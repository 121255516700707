import { Injectable } from "@angular/core";
import { loadTranslations } from "@angular/localize";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  public locales = [
    {
      name: "en",
      displayName: "English",
    },
    {
      name: "es",
      displayName: "Español",
    },
    {
      name: "ja",
      displayName: "日本語", // Japanese
    },
  ];

  // input should be just the lang, like from abp.localization.currentLanguage.name
  loadLanguage(lang): Promise<void> {
    return new Promise((resolve, reject) => {
      // if lang is en, don't.
      if (lang === "en") {
        resolve();
        return;
      }

      return fetch(`/assets/i18n/messages.${lang}.json`).then((response) => {
        if (!response.ok) {
          console.log("locale file not found, sticking to english");
          resolve();
        } else {
          console.log("locale file found, changing languages");

          return response.json().then((c) => {
            loadTranslations(c.translations);
            resolve();
          });
        }
      });
    });
  }

  setLangauge(languageName) {
    abp.utils.setCookieValue(
      "Abp.Localization.CultureName",
      languageName,
      new Date(new Date().getTime() + 5 * 365 * 86400000), // 5 year
      abp.appPath,
      environment.domain
    );
  }
  constructor() {}
}
