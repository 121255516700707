import { isPlatformBrowser } from "@angular/common";
import { inject, PLATFORM_ID } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { shareReplay, startWith, switchMap, tap } from "rxjs/operators";

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
export abstract class AbstractSingleCacheService<T, I> implements Resolve<T> {
  public cache: T = null;
  isLoading = true;
  abstract request(): Observable<T>;
  private cache$: Observable<T>;
  platFormId = inject(PLATFORM_ID);
  get get(): Observable<T> {
    if (!isPlatformBrowser(this.platFormId)) {
      return of(null);
    }
    if (!this.cache$) {
      this.cache$ = this.refresher().pipe(
        startWith(null),
        switchMap((_) => this.request()),
        tap((c) => {
          this.cache = c;
          this.isLoading = false;
        }),
        shareReplay(1)
      );
    }

    return this.cache$;
  }

  abstract refresher(): Observable<void>;

  /// Make it as a resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<T> {
    return this.get;
  }
}
