export class AppConsts {
  static localeMappings = [];

  static readonly userManagement = {
    defaultAdminUserName: "admin",
  };

  static readonly localization = {
    defaultLocalizationSourceName: "Calendr",
  };

  static readonly authorization = {
    encrptedAuthTokenName: "enc_auth_token",
  };
}
