<div>
  <h3 style="margin: 4px">{{ eventName }}</h3>
</div>

<div *ngIf="location" class="text-with-icon">
  <mat-icon class="icon-suffix">place</mat-icon>
  <span>{{ location }}</span>
</div>
<div *ngIf="meetinglink" class="text-with-icon">
  <mat-icon class="icon-suffix">meeting_room</mat-icon>
  <span
    ><a target="_blank" [href]="meetinglink">{{ meetinglink }}</a></span
  >
</div>
<div *ngIf="meetingPassword" class="text-with-icon">
  <mat-icon class="icon-suffix">password</mat-icon>
  <span>{{ meetingPassword }}</span>
</div>
<div class="text-with-icon">
  <mat-icon [inline]="false" class="icon-suffix">today</mat-icon>
  <strong>
    {{ start | date : "longDate" }} ({{ start | date : "EEEE" }})
  </strong>
</div>
<div class="text-with-icon">
  <mat-icon [inline]="false" class="icon-suffix">access_time</mat-icon>
  <strong>
    {{ start | date : (appSession.display24Hours ? "H:mm" : "h:mm a") }}
    -
    {{ end | date : (appSession.display24Hours ? "H:mm" : "h:mm a") }}
    ({{ duration }}

    <span i18n> minutes </span>)
  </strong>
</div>
<div class="text-with-icon">
  <mat-icon>map</mat-icon>
  {{ timeZone | timezone }}
</div>
<div *ngIf="description != ''" class="text-with-icon">
  <mat-icon>info</mat-icon>
  {{ description }}
</div>
