import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
//import { ClarityService } from "@app/clarity.service";
import { LocaleService } from "@app/locale.service";
import { environment } from "@environments/environment";
import { AbstractSingleCacheService } from "@shared/cache-services/abstract-single-cache.service";
import { DataChangedBroadcastService } from "@shared/cache-services/data-changed-broadcast.service";
import {
  ApplicationInfoDto,
  GetCurrentLoginInformationsOutput,
  SessionServiceProxy,
  TenantLoginInfoDto,
  UserLoginInfoDto,
} from "@shared/service-proxies/Session-client";
import { merge as _merge } from "lodash-es";

import { Observable, Subject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";

declare var _mfq: any;

@Injectable({
  providedIn: "root",
  deps: [SessionServiceProxy],
})
export class AppSessionService extends AbstractSingleCacheService<
  GetCurrentLoginInformationsOutput,
  string
> {
  refresher(): Observable<void> {
    return this._dataChangedBroadcastService.UserInfoChanged$;
  }
  private _tenant: TenantLoginInfoDto;
  private _application: ApplicationInfoDto;
  display24Hours = false;
  public tenantObservable: Observable<TenantLoginInfoDto | null>;
  public applicationObservable: Observable<ApplicationInfoDto | null>;
  public needstour = false;
  //public clarityService = inject(ClarityService);
  constructor(
    private _sessionService: SessionServiceProxy,
    private _dataChangedBroadcastService: DataChangedBroadcastService,
    private _router: Router,
    private localeService: LocaleService,
    private _httpClient: HttpClient
  ) {
    super();
    this.tenantObservable = new Subject<TenantLoginInfoDto>();
    this.applicationObservable = new Subject<ApplicationInfoDto>();

    // when the user logs in or changes something, remove the dialog

    this.tenantObservable = this.get.pipe(map((v) => v.tenant));
    this.applicationObservable = this.get.pipe(map((v) => v.application));
  }
  get application(): ApplicationInfoDto {
    return this._application;
  }

  get tenant(): TenantLoginInfoDto {
    return this._tenant;
  }

  get tenantId(): number {
    return this.tenant ? this.tenant.id : null;
  }

  get user(): UserLoginInfoDto {
    return this.cache.user;
  }
  get user$(): Observable<UserLoginInfoDto> {
    return this.get.pipe(map((v) => v?.user));
  }

  get fullname(): string {
    return this.user ? this.user.name : "";
  }

  public request(): Observable<GetCurrentLoginInformationsOutput> {
    console.log("Initializing app");
    return this.getUserConfiguration().pipe(
      switchMap((_v, _i) => {
        console.log("Initializing session");

        return this._sessionService.getCurrentLoginInformations();
      }),
      map((result: GetCurrentLoginInformationsOutput) => {
        this._application = result.application;
        this._tenant = result.tenant;

        console.debug("Logininfo");
        if (result.user !== undefined) {
          if (!result.user.hasCompletedOnboarding) {
            this.onFirstLogin();

            this._sessionService.completeOnboarding().subscribe(() => {
              result.user.hasCompletedOnboarding = true;
              this._router.navigate(["/dashboard/calendars/create"]);
            });
          }

          // mouseflow
          // push username to mouseflow if avaiable
          if (typeof _mfq !== "undefined") {
            console.debug("logging:" + result.user.userName);
            _mfq.push(["setVariable", "username", result.user.userName]);
          }

          // clarity
          // push username to clarity if available
          //this.clarityService.clarity.setTag("username", result.user.userName);
        }

        return result;
      })
    );
  }

  onFirstLogin() {
    this.needstour = true;
    // this._calendarService.createDefaultCalendar().subscribe(c => {
    //     this.needstour = true;
    // }
    // );

    // send to onboarding

    // message
    // abp.message.info(
    //   "We are currently improving the website based on user feedback. If you have any problems - we want to know! Please hit the feedback button and we will get back to you shortly. Thank you!",
    //   "Welcome!",
    //   {
    //     icon: "/assets/images/CircleC.jpeg",
    //   }
    // );

    // detect and set timezone

    this._sessionService.completeOnboarding().subscribe(() => {
      this._sessionService
        .setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .subscribe(() => {
          this._dataChangedBroadcastService.UserInfoChanged$.next();
        });
    });

    // send signup event to GA
    gtag("event", "first_login", {});
  }

  changeTenantIfNeeded(tenantId?: number): boolean {
    if (this.isCurrentTenant(tenantId)) {
      return false;
    }

    abp.multiTenancy.setTenantIdCookie(tenantId);
    location.reload();
    return true;
  }

  private isCurrentTenant(tenantId?: number) {
    if (!tenantId && this.tenant) {
      return false;
    } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
      return false;
    }
    return true;
  }

  ///this is called when the app starts anyway, and is only here so that we can easily recall it if we need to
  private getUserConfiguration(): Observable<any> {
    const cookieLangValue = abp.utils.getCookieValue(
      "Abp.Localization.CultureName"
    );
    const token = abp.auth.getToken();

    const requestHeaders = {
      "Abp.TenantId": `${abp.multiTenancy.getTenantIdCookie()}`,
      ".AspNetCore.Culture": `c=${cookieLangValue}|uic=${cookieLangValue}`,
    };

    if (token) {
      requestHeaders["Authorization"] = `Bearer ${token}`;
    }

    return this._httpClient
      .get<any>(
        environment.remoteServiceBaseUrl + "/AbpUserConfiguration/GetAll",
        { headers: requestHeaders }
      )
      .pipe(
        tap((response) => {
          const result = response.result;

          console.log(
            "user language is " + result.localization.currentCulture.name
          );
          // if the user is logged in, save their language

          this.localeService.setLangauge(
            result.localization.currentCulture.name
          );

          _merge(abp, result);

          abp.clock.provider = this.getCurrentClockProvider(
            result.clock.provider
          );
        })
      );
  }

  private getCurrentClockProvider(
    currentProviderName: string
  ): abp.timing.IClockProvider {
    if (currentProviderName === "unspecifiedClockProvider") {
      return abp.timing.unspecifiedClockProvider;
    }

    if (currentProviderName === "utcClockProvider") {
      return abp.timing.utcClockProvider;
    }

    return abp.timing.localClockProvider;
  }
}
