export function getCookieValue(key: string) {
  if (!document) {
    return null;
  }
  var equalities = document?.cookie.split("; ");
  for (var i = 0; i < equalities.length; i++) {
    if (!equalities[i]) {
      continue;
    }

    // skips null cookie
    var splitted = equalities[i].split("=");
    if (splitted.length != 2 || splitted[1] == "") {
      continue;
    }

    if (decodeURIComponent(splitted[0]) === key) {
      return decodeURIComponent(splitted[1] || "");
    }
  }

  return null;
}
